<!-- 矩阵式报表--测试 -->
<template>
  <div class="draggables">
    <!-- 选择一个列分组 -->
    <el-table
      ref="martrixeltable"
      class="one_group_table"
      v-if="transversegroupList.length === 1"
      :data="rowColumnData"
      :span-method="objectSpanMethod"
      style="width: 100%"
      height="100%"
      border
      :fit="false"
      :cell-style="cellClass"
      @cell-click="cellclick"
      v-loadmore="loadmore"
    >
      <!-- 所选行字段 -->
      <el-table-column
        v-for="(item, index) in lengthwaysgroupList"
        :key="index + item.id"
        :prop="item.schemefield_name_alias"
        :fixed="index==0&&appReport?'left':false"
        min-width="150"
      >
        <template #header>
          <div class="report_header">
            <div ref="headerData">{{ item.labelName }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                :appReport="appReport"
                @sortingOrder="sortingOrder(item, $event)"
                @deleteGroupRow="deleteGroupRow(item)"
                @deleteGroupColumn="deleteGroupColumn(item)"
                @deleteColumn="deleteColumn(index)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.mapLengthGroup &&
              scope.row.mapLengthGroup.detailOrSubtotal === 'maxtotal' &&
              index === 0
            "
          >
            {{ $t("label.reportsSubscriptionPage.condition.aggregate") }}
          </div>
          <div
            v-else-if="
              scope.row.mapLengthGroup &&
              scope.row.mapLengthGroup.detailOrSubtotal === 'subtotal' &&
              index === 1
            "
          >
            {{ $t("label.subtotal") }}
          </div>
          <div
            v-else
            :class="
              isNameFiled(scope.row.mapLengthGroup, item) || isMapTaskRel(item)
                ? 'cell_word'
                : 'cell_cursor'
            "
          >
            <!-- 判断是否可跳转，若是可以跳转则添加选项卡打开功能 -->
            <a
              v-if="isNameFiled(scope.row.mapLengthGroup, item)"
              class="nameFiled"
              @click.exact="cardViewjump($event,scope.row,item, 'detail')"
              @click.ctrl.exact="cardViewjump($event,scope.row,item, true)"
              @click.meta.exact="cardViewjump($event,scope.row,item, true)"
            >
              <span v-if="isMapTaskRel(item)">{{
                mapTaskRel[
                  scope.row.mapLengthGroup[item.schemefield_name_alias]
                ]
              }}</span>
              <span v-else>{{
                scope.row.mapLengthGroup[item.schemefield_name_alias]
              }}</span>
            </a>
            <span v-else-if="isMapTaskRel(item)">{{
              mapTaskRel[scope.row.mapLengthGroup[item.schemefield_name_alias]]
            }}</span>
            <span v-else>
              <!-- 复选框字段 -->
              <span
                v-if="
                  getCheckboxFiled(
                    item,
                    scope.row.mapLengthGroup[item.schemefield_name_alias]
                  ).isCheckboxFiled
                "
              >
                <el-checkbox
                  :value="
                    getCheckboxFiled(
                      item,
                      scope.row.mapLengthGroup[item.schemefield_name_alias]
                    ).checkboxFiledValue
                  "
                  disabled
                ></el-checkbox>
              </span>
              <span v-else>
                {{
                  scope.row.mapLengthGroup[item.schemefield_name_alias]
                }}</span
              ></span
            >
          </div>
        </template>
      </el-table-column>
      <!-- 所选列字段 -->
      <el-table-column
        v-for="(item, index) in transversegroupList"
        :key="index + item.id"
        min-width="200"
      >
        <template #header>
          <div class="report_header">
            <div ref="headerData">
              {{ item.labelName }}
            </div>
            <div @click="clickImg(item)">
              <colOperation
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :appReport="appReport"
                :judgeNumber="judgeNumber"
                @sortingOrder="sortingOrder(item, $event)"
                @deleteGroupRow="deleteGroupRow(item)"
                @deleteGroupColumn="deleteGroupColumn(item)"
                @deleteColumn="deleteColumn(index)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div
            :class="
              item1.schemefieldnamealias === 'totalrecord' && lineCountButt
                ? 'cell_cursor'
                : 'cell_word'
            "
            class="column_group_cell"
            v-for="item1 in scope.row.listLengthData"
            :key="item1.schemefieldnamealias"
          >
            <!-- 记录数 -->
            <div
              v-if="
                item1.schemefieldnamealias === 'totalrecord' && lineCountButt
              "
            >
              {{ item1.fieldlabel || item1.labelName }}
            </div>
            <!-- 统计值的求和 -->
            <div
              v-if="
                item1.summaryway == 'sum' &&
                item1.schemefieldnamealias != 'totalrecord'
              "
            >
              {{ item1.fieldlabel || item1.labelName }}
              {{ $t("label.summation") }}
            </div>
            <div v-if="item1.summaryway == 'avg'">
              {{ item1.fieldlabel || item1.labelName }}
              {{ $t("label.average") }}
            </div>
            <div v-if="item1.summaryway == 'max'">
              {{ item1.fieldlabel || item1.labelName }}
              {{ $t("label.maximum") }}
            </div>
            <div v-if="item1.summaryway == 'min'">
              {{ item1.fieldlabel || item1.labelName }}
              {{ $t("label.minimun") }}
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 列字段所包含内容 -->
      <el-table-column
        v-for="item in chooseColumnTypes"
        :key="item.alias"
        :label="item.value"
        :prop="item.alias"
        min-width="200"
      >
        <template #header>
          <!-- 复选框字段 -->
          <span
            v-if="
              getCheckboxFiled(transversegroupList[0], item.value)
                .isCheckboxFiled
            "
          >
            <el-checkbox
              :value="
                getCheckboxFiled(transversegroupList[0], item.value)
                  .checkboxFiledValue
              "
              disabled
            ></el-checkbox>
          </span>
          <span v-else-if="isMapTaskRel(transversegroupList[0])">{{
            mapTaskRel[item.value]
          }}</span>
          <span v-else> {{ item.value }}</span>
        </template>
        <template slot-scope="scope">
          <div
            class="cell_word"
            v-for="item1 in scope.row.listLengthData"
            :key="item1.schemefieldnamealias"
          >
            <div v-if="item1.schemefieldnamealias === 'totalrecord'">
              <span v-if="lineCountButt">{{ item1[item.alias] }}</span>
            </div>
            <div v-else>
              <!-- 没有统计值则设置为0 -->
              <div v-if="!item1[item.alias]">0</div>
              <div v-else>{{ item1[item.alias] }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 汇总公式 -->
      <el-table-column
        v-for="item in expressionSummary.filter((ex) => {
          return ex.length > 0;
        })"
        :key="item.apiname"
        :prop="item.apiname"
        style="width: auto; padding: 3px 6px 3px 4px"
        min-width="150"
      >
        <template #header>
          <div class="report_header">
            <div ref="headerData">{{ item.apiname }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :chooseRowFileds="chooseRowFileds"
                :groupIds="groupIds"
                :indexData="item"
                :appReport="appReport"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div style="padding-left: 10px">
            {{ scope.row[item.apiname] }}
          </div>
        </template>
      </el-table-column>
      <!-- 矩阵式报表，如果列超出限制，需要在合计前加一列，全部数据展示为... -->
      <el-table-column label="..." v-if="isHavaMoreColumns">
        <template>
          <div>...</div>
        </template>
      </el-table-column>
      <!-- 合计 -->
      <el-table-column
        :label="$t('label.reportsSubscriptionPage.condition.aggregate')"
        min-width="150"
        class="total-table"
        prop="total"
      >
        <template slot-scope="scope">
          <div
            v-for="item2 in scope.row.listLengthData"
            :key="item2.schemefieldnamealias"
            class="cell_word"
          >
            <div v-if="item2.schemefieldnamealias === 'totalrecord'">
              <span v-if="lineCountButt">{{ item2.total }}</span>
            </div>
            <div v-else>
              <span v-if="!item2.total">0</span>
              <span v-else>{{ item2.total }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 选择两个列分组 -->
    <el-table
      class="two_group_table"
      ref="martrixTwoeltable"
      v-else
      :data="rowColumnData"
      :span-method="objectSpanMethod"
      style="width: 100%"
      height="100%"
      border
      :cell-style="cellClass"
      @cell-click="cellclick"
    >
      <!-- 所选行字段 -->
      <el-table-column
        v-for="(item, index) in lengthwaysgroupList"
        :key="index + item.id"
        :prop="item.schemefield_name_alias"
        min-width="150"
      >
        <template #header>
          <div class="report_header">
            <div ref="headerData">{{ item.labelName }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :appReport="appReport"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @sortingOrder="sortingOrder(item, $event)"
                @deleteGroupRow="deleteGroupRow(item)"
                @deleteGroupColumn="deleteGroupColumn(item)"
                @deleteColumn="deleteColumn(index)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.mapLengthGroup &&
              scope.row.mapLengthGroup.detailOrSubtotal === 'maxtotal' &&
              index === 0
            "
          >
            {{ $t("label.reportsSubscriptionPage.condition.aggregate") }}
          </div>
          <div
            v-else-if="
              scope.row.mapLengthGroup &&
              scope.row.mapLengthGroup.detailOrSubtotal === 'subtotal' &&
              index === 1
            "
          >
            {{ $t("label.subtotal") }}
          </div>
          <div v-else-if="scope.row.mapLengthGroup" class="cell_word">
            <!-- 判断是否可跳转，若是可以跳转则添加选项卡打开功能 -->
            <a
              v-if="isNameFiled(scope.row.mapLengthGroup, item)"
              class="nameFiled"
              @click.exact="cardViewjump($event,scope.row,item, 'detail')"
              @click.ctrl.exact="cardViewjump($event,scope.row,item, true)"
              @click.meta.exact="cardViewjump($event,scope.row,item, true)"
            >
              <span v-if="isMapTaskRel(item)">{{
                mapTaskRel[
                  scope.row.mapLengthGroup[item.schemefield_name_alias]
                ]
              }}</span>
              <span v-else>{{
                scope.row.mapLengthGroup[item.schemefield_name_alias]
              }}</span>
            </a>
            <span v-else-if="isMapTaskRel(item)">{{
              mapTaskRel[scope.row.mapLengthGroup[item.schemefield_name_alias]]
            }}</span>
            <span v-else>
              <!-- 复选框字段 -->
              <span
                v-if="
                  getCheckboxFiled(
                    item,
                    scope.row.mapLengthGroup[item.schemefield_name_alias]
                  ).isCheckboxFiled
                "
              >
                <el-checkbox
                  :value="
                    getCheckboxFiled(
                      item,
                      scope.row.mapLengthGroup[item.schemefield_name_alias]
                    ).checkboxFiledValue
                  "
                  disabled
                ></el-checkbox>
              </span>
              <span v-else>
                {{
                  scope.row.mapLengthGroup[item.schemefield_name_alias]
                }}</span
              >
            </span>
          </div>
          <div v-else></div>
        </template>
      </el-table-column>
      <!-- 所选列字段 -->
      <el-table-column min-width="200">
        <template #header>
          <div class="report_header">
            <div v-if="transversegroupList.length == 2">
              {{ transversegroupList[0].labelName }}
            </div>
            <div @click="clickImg(transversegroupList[0])">
              <colOperation
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :appReport="appReport"
                :indexData="transversegroupList[0]"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @sortingOrder="sortingOrder(transversegroupList[0], $event)"
                @deleteGroupRow="deleteGroupRow(transversegroupList[0])"
                @deleteGroupColumn="deleteGroupColumn(transversegroupList[0])"
                @deleteColumn="deleteColumn(index)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <el-table-column min-width="200">
          <template #header>
            <div class="report_header">
              <div v-if="transversegroupList.length == 2">
                {{ transversegroupList[1].labelName }}
              </div>
              <div @click="clickImg(transversegroupList[1])">
                <colOperation
                  :chooseRowFileds="chooseRowFileds"
                  :choosedColumnFileds="choosedColumnFileds"
                  :groupIds="groupIds"
                  :appReport="appReport"
                  :indexData="transversegroupList[1]"
                  :dateGroupings="dateGroupings"
                  :statisticsDatas="statisticsDatas"
                  :sortDatas="sortDatas"
                  :judgeNumber="judgeNumber"
                  @sortingOrder="sortingOrder(transversegroupList[1], $event)"
                  @deleteGroupRow="deleteGroupRow(transversegroupList[1])"
                  @deleteGroupColumn="deleteGroupColumn(transversegroupList[1])"
                  @deleteColumn="deleteColumn(index)"
                  @clickColOperation="clickColOperation"
                  @statisticsCurs="statisticsCurs"
                  ref="test"
                ></colOperation>
              </div>
            </div>
          </template>
          <template slot-scope="scope">
            <div
              class="cell_word column_group_cell"
              v-for="item1 in scope.row.listLengthData"
              :key="item1.schemefieldnamealias"
            >
              <!-- 记录数 -->
              <div
                v-if="
                  item1.schemefieldnamealias === 'totalrecord' && lineCountButt
                "
              >
                {{ item1.fieldlabel || item1.labelName }}
              </div>
              <!-- 统计值的求和 -->
              <div
                v-if="
                  item1.summaryway == 'sum' &&
                  item1.schemefieldnamealias != 'totalrecord'
                "
              >
                {{ item1.fieldlabel || item1.labelName }}
                {{ $t("label.summation") }}
              </div>
              <div v-if="item1.summaryway == 'avg'">
                {{ item1.fieldlabel || item1.labelName }}
                {{ $t("label.average") }}
              </div>
              <div v-if="item1.summaryway == 'max'">
                {{ item1.fieldlabel || item1.labelName }}
                {{ $t("label.maximum") }}
              </div>
              <div v-if="item1.summaryway == 'min'">
                {{ item1.fieldlabel || item1.labelName }}
                {{ $t("label.minimun") }}
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <!-- 列字段所包含内容 -->
      <el-table-column
        v-for="item in chooseColumnTypes"
        :key="item.alias"
        :label="item.value"
        :prop="item.alias"
      >
        <template #header>
          <!-- 复选框字段 -->
          <span
            v-if="
              getCheckboxFiled(transversegroupList[0], item.value)
                .isCheckboxFiled
            "
          >
            <el-checkbox
              :value="
                getCheckboxFiled(transversegroupList[0], item.value)
                  .checkboxFiledValue
              "
              disabled
            ></el-checkbox>
          </span>
          <span v-else-if="isMapTaskRel(transversegroupList[0])">{{
            mapTaskRel[item.value]
          }}</span>
          <span v-else> {{ item.value }}</span>
        </template>
        <el-table-column
          v-for="item1 in item.listTranGroupData"
          :key="item1.alias"
          :label="item.value + '_' + item1.value"
          :prop="item.alias + item1.alias"
          min-width="150"
        >
          <template #header>
            <!-- 复选框字段 -->
            <span
              v-if="
                getCheckboxFiled(transversegroupList[1], item1.value)
                  .isCheckboxFiled
              "
            >
              <el-checkbox
                :value="
                  getCheckboxFiled(transversegroupList[1], item1.value)
                    .checkboxFiledValue
                "
                disabled
              ></el-checkbox>
            </span>
            <span v-else-if="isMapTaskRel(transversegroupList[1])">{{
              mapTaskRel[item1.value]
            }}</span>
            <span v-else> {{ item1.value }}</span>
          </template>
          <template slot-scope="scope">
            <div
              v-for="item2 in scope.row.listLengthData"
              :key="item2.schemefieldnamealias"
            >
              <div v-if="item2.schemefieldnamealias === 'totalrecord'">
                <span v-if="lineCountButt">{{
                  item2[item.alias + item1.alias]
                }}</span>
              </div>
              <div v-else>
                <!-- 没有统计值则设置为0 -->
                <div v-if="!item2[item.alias + item1.alias]">0</div>
                <div v-else>
                  {{ item2[item.alias + item1.alias] }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 小计 -->
        <el-table-column
          min-width="140"
          :label="$t('label.subtotal')"
          :prop="`${item.value}_${item.alias}`"
        >
          <template #header>
            <div>{{ $t("label.subtotal") }}</div>
          </template>
          <template slot-scope="scope">
            <div
              v-for="item2 in scope.row.listLengthData"
              :key="item2.schemefieldnamealias"
            >
              <div v-if="item2.schemefieldnamealias === 'totalrecord'">
                <span v-if="lineCountButt">{{ item2[item.alias] }}</span>
              </div>
              <div v-else>
                <!-- 没有统计值则设置为0 -->
                <div v-if="!item2[item.alias]">0</div>
                <div v-else>
                  {{ item2[item.alias] }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <!-- 汇总公式 -->
      <el-table-column
        v-for="item in expressionSummary.filter((ex) => {
          return ex.length > 0;
        })"
        :key="item.apiname"
        :prop="item.apiname"
        style="width: auto; padding: 3px 6px 3px 4px"
        min-width="150"
      >
        <template #header>
          <div class="report_header">
            <div ref="headerData">{{ item.apiname }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :chooseRowFileds="chooseRowFileds"
                :groupIds="groupIds"
                :indexData="item"
                :appReport="appReport"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div style="padding-left: 10px">
            {{ scope.row[item.apiname] }}
          </div>
        </template>
      </el-table-column>
      <!-- 矩阵式报表，如果列超出限制，需要在合计前加一列，全部数据展示为... -->
      <el-table-column label="..." v-if="isHavaMoreColumns">
        <template>
          <div>...</div>
        </template>
      </el-table-column>
      <!-- 合计 -->
      <el-table-column
        :label="$t('label.reportsSubscriptionPage.condition.aggregate')"
        min-width="140"
        prop="total"
      >
        <template slot-scope="scope">
          <div
            v-for="item2 in scope.row.listLengthData"
            :key="item2.schemefieldnamealias"
          >
            <div v-if="item2.schemefieldnamealias === 'totalrecord'">
              <span v-if="lineCountButt">{{ item2.total }}</span>
            </div>
            <div v-else>
              <span v-if="!item2.total">0</span>
              <span v-else>{{ item2.total }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import colOperation from "./colOperation";

export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding, vnode) {
        let that = vnode.context;
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 300;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (
            scrollDistance <= sign &&
            that.runTo === true &&
            that.total <= that.TabularPagenum
          ) {
            that.total += 1;
            that.loadmore();
          }
          that.matrixTableScrollTop=this.scrollTop
        });
      },
    },
  },
  components: {
    colOperation,
  },
  props: {
        // 区分移动端报表
    appReport: {
      type: String,
      default: "",
    },
    //可以滚动加载的次数
    TabularPagenum: {
      type: Number,
      default: 1,
    },
    lengthwaysgroupList: {
      // 行分组集合
      type: Array,
      default: () => [],
    },
    // 是否是运行页
    runTo: {
      type: Boolean,
      default: false,
    },
    transversegroupList: {
      // 列分组集合
      type: Array,
      default: () => [],
    },
    rowColumnData: {
      type: Array,
      default: () => [],
    },
    chooseColumnTypes: {
      type: Array,
      default: () => [],
    },
    expressionSummary: {
      // 汇总公式
      type: Array,
      default: () => [],
    },
    isHavaMoreColumns: {
      // 矩阵式报表，如果列超出限制，需要在合计前加一列，全部数据展示为...
      type: Boolean,
      default: false,
    },
    objectSpanMethod: {
      // 合并行方法
      type: Function,
      default: () => {},
    },
    cellClass: {
      // 表格改变单元格颜色方法
      type: Function,
      default: () => {},
    },
    isNameFiled: {
      // 判断是否可跳转，后端已做过判断，isAddLink为true即可跳转
      type: Function,
      default: () => {},
    },
    changeMicrometerLevel: {
      // 将数值类型转换为千分位格式
      type: Function,
      default: () => {},
    },
    cardViewjump: {
      // 点击跳转详情页name
      type: Function,
      default: () => {},
    },
    getCheckboxFiled: {
      // 判断字段是否为复选框类型
      type: Function,
      default: () => {},
    },
    judgeNumber: {
      // 判断是否是数字方法
      type: Function,
      default: () => {},
    },
    isMapTaskRel: {
      // 判断是否需要从mapTaskRel里根据api值获取对应数据
      type: Function,
      default: () => {},
    },
    mapTaskRel: {
      // 判断是否需要从mapTaskRel里根据api值获取对应数据
      type: Object,
      default: () => {},
    },
    // colOperation组件需接收的数据
    chooseRowFileds: {
      type: Array,
      default: () => [],
    },
    choosedColumnFileds: {
      type: Array,
      default: () => [],
    },
    groupIds: {
      type: Array,
      default: () => [],
    },
    indexData: {
      type: Object,
      default: () => {},
    },
    dateGroupings: {
      type: Object,
      default: () => {},
    },
    statisticsDatas: {
      type: Object,
      default: () => {},
    },
    sortDatas: {
      type: Object,
      default: () => {},
    },
    detailButt: {
      // 详细信息行按钮
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectRow: "", // 选择的行
      selectColumn: "", // 选择的列
      total: 1,
      matrixTableScrollTop:0,//矩阵式报表滚动条位置
    };
  },
  computed: {
    // 是否显示行计数
    lineCountButt: function () {
      return this.$store.getters.getLineCountButt;
    },
  },
  created() {},
  mounted() {},
  methods: {
    loadmore() {
      this.$emit("loadMoreData", this.total);
    },
    // 点击单元格
    cellclick(row, column, cell, event) {
      // 判断若是属于行分组列或是两个列分组列，则不需请求接口获取详细信息数据
      if (
        column.property === undefined ||
        this.lengthwaysgroupList.some(
          (item) => item.schemefield_name_alias === column.property
        )
      ) {
        return;
      }
      this.selectRow = row;
      this.selectColumn = column;
      this.$emit("cellclick", row, column, cell, event);
    },
    clickImg(item) {
      this.$emit("clickImg", item);
    },
    sortingOrder(item, $event) {
      // 排序
      this.$emit("sortingOrder", item, $event);
    },
    deleteGroupRow() {
      this.$emit("deleteGroupRow", DataTransferItem);
    },
    deleteGroupColumn(item) {
      this.$emit("deleteGroupColumn", item);
    },
    deleteColumn(index) {
      this.$emit("deleteColumn", index);
    },
    clickColOperation(data) {
      this.$emit("clickColOperation", data);
    },
    // 统计
    statisticsCurs(data) {
      this.$emit("statisticsCurs", data);
    },
  },
};
</script>

<style lang='scss' scoped>
::v-deep .el-table th > .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>