<!-- 汇总式报表 -->
<template>
  <div class="draggable" v-if="showTable">
    <!-- 选择1/2/3个行分组，显示详细信息行 -->
    <el-table
      ref="summaryDetailelTable"
      v-if="isShowDetailData"
      :data="tableData"
      height="100%"
      v-loadmore="loadmore"
      border
      :span-method="objectSpanMethod"
      :fit="false"
      :cell-style="cellClass"
    >
      <!-- 所选行字段 -->
      <el-table-column
        v-for="(item, index) in transversegroupList"
        :key="index + item.id"
        :prop="item.schemefield_name_alias"
        :fixed="index==0 && appReport?'left':false"
        min-width="150"
        class="groupsClass"
      >
        <template #header>
          <div
            class="report_header"
            @drop="(e) => handleTargetDrop(e, index, 'group')"
            @dragover.prevent
          >
            <div ref="headerData">
              {{ item.labelName }}
            </div>
            <div @click="clickImg(item)">
              <colOperation
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                :appReport="appReport"
                @sortingOrder="sortingOrder(item, $event)"
                @deleteGroupRow="deleteGroupRow(item)"
                @deleteGroupColumn="deleteGroupColumn(item)"
                @deleteColumn="deleteColumn(index)"
                @change="operationFun(item.schemefield_name_alias, $event)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <!-- 有时候行分组数据不显示，添加一个隐藏的div即可 -->
          <div v-show="false">{{ scope.row.level01 }}</div>
          <div v-if="scope.row.level01 == 'a' && index === 0">
            {{ $t("label.subtotal") }}
            <span v-show="lineCountButt">({{ scope.row.totalrecord }})</span>
          </div>
          <div v-if="scope.row.level01 == 'b' && index === 1">
            {{ $t("label.subtotal") }}
            <span v-show="lineCountButt">({{ scope.row.totalrecord }})</span>
          </div>
          <div v-if="scope.row.level01 == 'c' && index === 2">
            {{ $t("label.subtotal") }}
            <span v-show="lineCountButt">({{ scope.row.totalrecord }})</span>
          </div>
          <div v-if="scope.row.level01 === 'maxtotal' && index === 0">
            {{ $t("label.reportsSubscriptionPage.condition.aggregate") }}
            <span v-show="lineCountButt">({{ scope.row.totalrecord }})</span>
          </div>
          <div v-if="scope.row.level01 == 'd'">
            <!-- 判断是否可跳转，若是可以跳转则添加选项卡打开功能 -->
            <a
              v-if="isNameFiled(scope.row, item)"
              class="nameFiled"
              @click.exact="cardViewjump($event,scope.row,item, 'detail')"
              @click.ctrl.exact="cardViewjump($event,scope.row,item, true)"
              @click.meta.exact="cardViewjump($event,scope.row,item, true)"
            >
              <span v-if="isMapTaskRel(item)">{{
                mapTaskRel[scope.row[item.schemefield_name_alias]]
              }}</span>
              <span v-else>{{ scope.row[item.schemefield_name_alias] }}</span>
            </a>
            <span v-else-if="isMapTaskRel(item)">{{
              mapTaskRel[scope.row[item.schemefield_name_alias]]
            }}</span>
            <span v-else>
              <!-- 复选框字段 -->
              <span
                v-if="
                  getCheckboxFiled(item, scope.row[item.schemefield_name_alias])
                    .isCheckboxFiled
                "
              >
                <el-checkbox
                  :value="
                    getCheckboxFiled(
                      item,
                      scope.row[item.schemefield_name_alias]
                    ).checkboxFiledValue
                  "
                  disabled
                ></el-checkbox>
              </span>
              <span v-else>
                {{ scope.row[item.schemefield_name_alias] }}</span
              ></span
            >
          </div>
        </template>
      </el-table-column>
      <!-- 其他字段 -->
      <el-table-column
        v-for="(item, index) in listCheckedFields.filter((l) => {
          return l.id !== 'totalrecord' && groupIds.indexOf(l.id) == -1;
        })"
        :key="index + item.id"
        :prop="item.schemefield_name_alias"
        min-width="150"
      >
        <template #header>
          <div
            class="report_header"
            @drop="(e) => handleTargetDrop(e, index, 'field')"
            @dragover.prevent
          >
            <div ref="headerData">{{ item.labelName }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :appReport="appReport"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @sortingOrder="sortingOrder(item, $event)"
                @deleteGroupRow="deleteGroupRow(item)"
                @deleteGroupColumn="deleteGroupColumn(item)"
                @deleteColumn="deleteColumn(index)"
                @change="operationFun(item.schemefield_name_alias, $event)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div
            :class="isNameFiled(scope.row, item) ? 'cell_word' : 'cell_cursor'"
          >
            <!-- 详细信息行且无统计 -->
            <div
              v-if="
                scope.row.level01 === 'd' &&
                scope.row[item.schemefield_name_alias] !== undefined
              "
              :class="{ number_style: judgeNumber(item) }"
            >
              <span v-if="judgeNumber(item)">{{
                scope.row[item.schemefield_name_alias]
              }}</span>
              <div v-else>
                <!-- 判断是否可跳转，若是可以跳转则添加选项卡打开功能 -->
                <a
                  v-if="isNameFiled(scope.row, item)"
                  class="nameFiled"
                  @click.exact="cardViewjump($event,scope.row,item, 'detail')"
                  @click.ctrl.exact="cardViewjump($event,scope.row,item, true)"
                  @click.meta.exact="cardViewjump($event,scope.row,item, true)"
                >
                  <span v-if="isMapTaskRel(item)">{{
                    mapTaskRel[scope.row[item.schemefield_name_alias]]
                  }}</span>
                  <span v-else>{{
                    scope.row[item.schemefield_name_alias]
                  }}</span>
                </a>
                <span v-else-if="isMapTaskRel(item)">{{
                  mapTaskRel[scope.row[item.schemefield_name_alias]]
                }}</span>
                <span v-else>
                  <!-- 复选框字段 -->
                  <span
                    v-if="
                      getCheckboxFiled(
                        item,
                        scope.row[item.schemefield_name_alias]
                      ).isCheckboxFiled
                    "
                  >
                    <el-checkbox
                      :value="
                        getCheckboxFiled(
                          item,
                          scope.row[item.schemefield_name_alias]
                        ).checkboxFiledValue
                      "
                      disabled
                    ></el-checkbox>
                  </span>
                  <span v-else>
                    {{ scope.row[item.schemefield_name_alias] }}</span
                  ></span
                >
              </div>
            </div>
            <!-- 详细信息行有统计，此时只有统计字段，无单个字段了 -->
            <div
              v-if="
                scope.row.level01 == 'd' &&
                scope.row[item.schemefield_name_alias] === undefined
              "
              :class="{ number_style: judgeNumber(item) }"
            >
              <span v-if="judgeNumber(item)">
                {{
                  scope.row[item.schemefield_name_alias + "$sum"] ||
                  scope.row[item.schemefield_name_alias + "$avg"] ||
                  scope.row[item.schemefield_name_alias + "$max"] ||
                  scope.row[item.schemefield_name_alias + "$min"]
                }}
              </span>
              <span v-else>
                {{
                  scope.row[item.schemefield_name_alias + "$sum"] ||
                  scope.row[item.schemefield_name_alias + "$avg"] ||
                  scope.row[item.schemefield_name_alias + "$max"] ||
                  scope.row[item.schemefield_name_alias + "$min"]
                }}
              </span>
            </div>
            <!-- 总和 -->
            <div
              class="data_style"
              v-if="
                scope.row[item.schemefield_name_alias + '$sum'] !== undefined
              "
              :class="{ number_style: judgeNumber(item) }"
            >
              <div v-if="scope.row.level01 !== 'd'">
                <div>
                  {{ $t("label.summation") }}：
                  <span v-if="judgeNumber(item)">{{
                    scope.row[item.schemefield_name_alias + "$sum"]
                  }}</span>
                  <span v-else>{{
                    scope.row[item.schemefield_name_alias + "$sum"]
                  }}</span>
                </div>
              </div>
            </div>
            <!-- 平均值 -->
            <div
              class="data_style"
              v-if="
                scope.row[item.schemefield_name_alias + '$avg'] !== undefined
              "
              :class="{ number_style: judgeNumber(item) }"
            >
              <div v-if="scope.row.level01 !== 'd'">
                <div>
                  {{ $t("label.average") }}：
                  <span v-if="judgeNumber(item)">{{
                    scope.row[item.schemefield_name_alias + "$avg"]
                  }}</span>
                  <span v-else>{{
                    scope.row[item.schemefield_name_alias + "$avg"]
                  }}</span>
                </div>
              </div>
            </div>
            <!-- 最大值 -->
            <div
              class="data_style"
              v-if="
                scope.row[item.schemefield_name_alias + '$max'] !== undefined
              "
              :class="{ number_style: judgeNumber(item) }"
            >
              <div v-if="scope.row.level01 !== 'd'">
                <div>
                  {{ $t("label.maximum") }}：
                  <span v-if="judgeNumber(item)">{{
                    scope.row[item.schemefield_name_alias + "$max"]
                  }}</span>
                  <span v-else>{{
                    scope.row[item.schemefield_name_alias + "$max"]
                  }}</span>
                </div>
              </div>
            </div>
            <!-- 最小值 -->
            <div
              class="data_style"
              v-if="
                scope.row[item.schemefield_name_alias + '$min'] !== undefined
              "
              :class="{ number_style: judgeNumber(item) }"
            >
              <div v-if="scope.row.level01 !== 'd'">
                <div>
                  {{ $t("label.minimun") }}：
                  <span v-if="judgeNumber(item)">{{
                    scope.row[item.schemefield_name_alias + "$min"]
                  }}</span>
                  <span v-else>{{
                    scope.row[item.schemefield_name_alias + "$min"]
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 行级公式 -->
      <el-table-column
        :prop="expressionLine[0].apiname"
        style="width: auto; padding: 3px 6px 3px 4px"
        v-if="expressionLine.length > 0"
        min-width="150"
      >
        <template #header>
          <div class="report_header">
            <div>{{ expressionLine[0].apiname }}</div>
            <div @click="clickImg(expressionLine[0])">
              <colOperation
                :appReport="appReport"
                :chooseRowFileds="chooseRowFileds"
                :groupIds="groupIds"
                :indexData="expressionLine[0]"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div style="padding-left: 10px" v-if="scope.row.level01 === 'd'">
            {{ scope.row["t0" + expressionLine[0].apiname] }}
          </div>
        </template>
      </el-table-column>
      <!-- 汇总公式 -->
      <el-table-column
        v-for="(item, index) in expressionSummary.filter((ex) => {
          return ex.length > 0;
        })"
        :key="index"
        :prop="item.apiname"
        style="width: auto; padding: 3px 6px 3px 4px"
        min-width="150"
      >
        <template #header>
          <div class="report_header">
            <div ref="headerData">{{ item.apiname }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :appReport="appReport"
                :chooseRowFileds="chooseRowFileds"
                :groupIds="groupIds"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div style="padding-left: 10px">
            {{ scope.row[item.apiname] }}
          </div>
        </template>
      </el-table-column>
    </el-table>

    <!-- 汇总表不显示详细信息行 -->
    <el-table
      v-else
      ref="summaryelTable"
      :data="tableData"
      height="100%"
      border
      :span-method="objectSpanMethod"
      :fit="false"
      :cell-style="cellClass"
      v-loadmore="loadmore"
    >
      <!-- 所选行字段 -->
      <el-table-column
        v-for="(item, index) in transversegroupList"
        :key="index + item.id"
        :prop="item.schemefield_name_alias"
        min-width="150"
      >
        <template #header>
          <div
            class="report_header"
            @drop="(e) => handleTargetDrop(e, index, 'group')"
            @dragover.prevent
          >
            <div ref="headerData">{{ item.labelName }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :appReport="appReport"
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @sortingOrder="sortingOrder(item, $event)"
                @deleteGroupRow="deleteGroupRow(item)"
                @deleteGroupColumn="deleteGroupColumn(item)"
                @deleteColumn="deleteColumn(index)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <!-- 两个行分组则第一个行分组加小计，三个行分组则第一个，第二个行分组加小计 -->
          <div
            v-if="
              (transversegroupList.length === 2 &&
                scope.row.level01 == 'a' &&
                index === 0) ||
              (transversegroupList.length === 3 &&
                ((scope.row.level01 == 'b' && index === 1) ||
                  (scope.row.level01 == 'a' && index === 0)))
            "
          >
            {{ $t("label.subtotal") }}
          </div>
          <!-- 合计 -->
          <div
            v-else-if="
              (scope.row.level01 === 'total' ||
                scope.row.level01 === 'maxtotal') &&
              index === 0
            "
          >
            {{ $t("label.reportsSubscriptionPage.condition.aggregate") }}
          </div>
          <!-- 否则直接显示对应的分组名称 -->
          <div v-else>
            <!-- 判断是否可跳转，若是可以跳转则添加选项卡打开功能 -->
            <a
              v-if="isNameFiled(scope.row, item)"
              class="nameFiled"
              @click.exact="cardViewjump($event,scope.row,item, 'detail')"
              @click.ctrl.exact="cardViewjump($event,scope.row,item, true)"
              @click.meta.exact="cardViewjump($event,scope.row,item, true)"
            >
              <span v-if="isMapTaskRel(item)">{{
                mapTaskRel[scope.row[item.schemefield_name_alias]]
              }}</span>
              <span v-else>{{ scope.row[item.schemefield_name_alias] }}</span>
            </a>
            <span v-else-if="isMapTaskRel(item)">{{
              mapTaskRel[scope.row[item.schemefield_name_alias]]
            }}</span>
            <span v-else>
              <!-- 复选框字段 -->
              <span
                v-if="
                  getCheckboxFiled(item, scope.row[item.schemefield_name_alias])
                    .isCheckboxFiled
                "
              >
                <el-checkbox
                  :value="
                    getCheckboxFiled(
                      item,
                      scope.row[item.schemefield_name_alias]
                    ).checkboxFiledValue
                  "
                  disabled
                ></el-checkbox>
              </span>
              <span v-else>
                {{ scope.row[item.schemefield_name_alias] }}</span
              ></span
            >
          </div>
        </template>
      </el-table-column>
      <!-- 统计字段 -->
      <!-- 平均值 -->
      <el-table-column
        v-for="(item, index) in listAvgFields"
        :key="item.schemefield_name_alias"
        :prop="item.schemefield_name_alias"
        style="width: auto; padding: 3px 6px 3px 4px"
        min-width="150"
        class-name="filtered"
      >
        <template #header>
          <div class="report_header">
            <div>{{ item.labelName }}{{ $t("label.average") }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :appReport="appReport"
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @sortingOrder="sortingOrder(item, $event)"
                @deleteGroupRow="deleteGroupRow(item)"
                @deleteGroupColumn="deleteGroupColumn(item)"
                @deleteColumn="deleteColumn(index)"
                @change="operationFun(item.schemefield_name_alias, $event)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="number_style">
            {{ scope.row[item.schemefield_name_alias] }}
          </div>
        </template>
      </el-table-column>
      <!-- 最大值 -->
      <el-table-column
        v-for="(item, index) in listMaxFields"
        :key="item.schemefield_name_alias"
        :prop="item.schemefield_name_alias"
        style="width: auto; padding: 3px 6px 3px 4px"
        min-width="150"
        class-name="filtered"
      >
        <template #header>
          <div class="report_header">
            <div>{{ item.labelName }}{{ $t("label.maximum") }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :appReport="appReport"
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @sortingOrder="sortingOrder(item, $event)"
                @deleteGroupRow="deleteGroupRow(item)"
                @deleteGroupColumn="deleteGroupColumn(item)"
                @deleteColumn="deleteColumn(index)"
                @change="operationFun(item.schemefield_name_alias, $event)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="number_style">
            {{ scope.row[item.schemefield_name_alias] }}
          </div>
        </template>
      </el-table-column>
      <!-- 最小值 -->
      <el-table-column
        v-for="(item, index) in listMinFields"
        :key="item.schemefield_name_alias"
        :prop="item.schemefield_name_alias"
        style="width: auto; padding: 3px 6px 3px 4px"
        min-width="150"
        class-name="filtered"
      >
        <template #header>
          <div class="report_header">
            <div>{{ item.labelName }}{{ $t("label.minimun") }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :appReport="appReport"
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @sortingOrder="sortingOrder(item, $event)"
                @deleteGroupRow="deleteGroupRow(item)"
                @deleteGroupColumn="deleteGroupColumn(item)"
                @deleteColumn="deleteColumn(index)"
                @change="operationFun(item.schemefield_name_alias, $event)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="number_style">
            {{ scope.row[item.schemefield_name_alias] }}
          </div>
        </template>
      </el-table-column>
      <!-- 总和及记录数：根据行计数按钮控制记录数列是否显示 -->
      <el-table-column
        v-for="(item, index) in gatherFieldList.filter((field) => {
          return (
            field.summaryWay === 'sum' ||
            (field.summaryWay === 'count' && lineCountButt)
          );
        })"
        :key="item.schemefield_name_alias"
        :prop="item.schemefield_name_alias"
        style="width: auto; padding: 3px 6px 3px 4px"
        min-width="150"
        class-name="filtered"
      >
        <template #header>
          <div class="report_header">
            <div>
              <span>{{ item.labelName }}</span
              ><span v-if="item.summaryWay !== 'count'">{{
                $t("label.summation")
              }}</span>
            </div>
            <div @click="clickImg(item)">
              <colOperation
                :appReport="appReport"
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @sortingOrder="sortingOrder(item, $event)"
                @deleteGroupRow="deleteGroupRow(item)"
                @deleteGroupColumn="deleteGroupColumn(item)"
                @deleteColumn="deleteColumn(index)"
                @change="operationFun(item.schemefield_name_alias, $event)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div class="number_style">
            {{ scope.row[item.schemefield_name_alias] }}
          </div>
        </template>
      </el-table-column>
      <!-- 行级公式 -->
      <!-- 汇总公式 -->
      <el-table-column
        v-for="(item, index) in expressionSummary.filter((ex) => {
          return ex.length > 0;
        })"
        :key="index"
        :prop="item.schemefield_name_alias"
        style="width: auto; padding: 3px 6px 3px 4px"
        min-width="150"
      >
        <template #header>
          <div class="report_header">
            <div ref="headerData">{{ item.apiname }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :appReport="appReport"
                :chooseRowFileds="chooseRowFileds"
                :groupIds="groupIds"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div style="padding-left: 10px">
            {{ scope.row[item.apiname] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import colOperation from "./colOperation";

export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding, vnode) {
        let that = vnode.context;
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 300;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (
            scrollDistance <= sign &&
            that.runTo === true &&
            that.total <= that.TabularPagenum
          ) {
            that.total += 1;
            that.loadmore();
          }
          that.summaryTableScrollTop=this.scrollTop;
        });
      },
    },
  },
  components: {
    colOperation,
  },
  props: {
    // 区分移动端报表
    appReport: {
      type: String,
      default: "",
    },
    //可以滚动加载的次数
    TabularPagenum: {
      type: Number,
      default: 1,
    },
    transversegroupList: {
      type: Array,
      default: () => [],
    },
    // 是否是运行页
    runTo: {
      type: Boolean,
      default: false,
    },
    lengthwaysgroupList: {
      type: Array,
      default: () => [],
    },
    detailButt: {
      // 控制是否显示详细信息行按钮
      type: Boolean,
      default: false,
    },
    showRefresh: {
      // 控制是否显示点击刷新按钮
      type: Boolean,
      default: false,
    },
    tableData: {
      // 行数据
      type: Array,
      default: () => [],
    },
    listCheckedFields: {
      // 列数据
      type: Array,
      default: () => [],
    },
    expressionLine: {
      // 行级公式
      type: Array,
      default: () => [],
    },
    expressionSummary: {
      // 汇总公式
      type: Array,
      default: () => [],
    },
    listAvgFields: {
      // 平均值
      type: Array,
      default: () => [],
    },
    listMaxFields: {
      // 最大值
      type: Array,
      default: () => [],
    },
    listMinFields: {
      // 最小值
      type: Array,
      default: () => [],
    },
    gatherFieldList: {
      // 总和及记录数
      type: Array,
      default: () => [],
    },
    objectSpanMethod: {
      // 合并行方法
      type: Function,
      default: () => {},
    },
    cellClass: {
      // 表格改变单元格颜色方法
      type: Function,
      default: () => {},
    },
    isNameFiled: {
      // 判断是否可跳转，后端已做过判断，isAddLink为true即可跳转
      type: Function,
      default: () => {},
    },
    changeMicrometerLevel: {
      // 将数值类型转换为千分位格式
      type: Function,
      default: () => {},
    },
    cardViewjump: {
      // 点击跳转详情页name
      type: Function,
      default: () => {},
    },
    getCheckboxFiled: {
      // 判断字段是否为复选框类型
      type: Function,
      default: () => {},
    },
    judgeNumber: {
      // 判断是否是数字方法
      type: Function,
      default: () => {},
    },
    isMapTaskRel: {
      // 判断是否需要从mapTaskRel里根据api值获取对应数据
      type: Function,
      default: () => {},
    },
    mapTaskRel: {
      // 判断是否需要从mapTaskRel里根据api值获取对应数据
      type: Object,
      default: () => {},
    },
    // colOperation组件需接收的数据
    chooseRowFileds: {
      type: Array,
      default: () => [],
    },
    choosedColumnFileds: {
      type: Array,
      default: () => [],
    },
    groupIds: {
      type: Array,
      default: () => [],
    },
    indexData: {
      type: Object,
      default: () => {},
    },
    dateGroupings: {
      type: Object,
      default: () => {},
    },
    statisticsDatas: {
      type: Object,
      default: () => {},
    },
    sortDatas: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      total: 1,
      isShowDetailData: false, // 汇总表是否显示详细信息数据：判断属于汇总式报表中的哪种类型
      showTable: true,
      chooseRowFiledsData: [], // 组件中定义的选择的组行数据，可能涉及到拖拽组行数据
      chooseReportColumnsData: [], // 在字段列表拖拽至报表列时，组件中定义的报表列数据
      summaryTableScrollTop:0,//汇总式报表滚动条位置
    };
  },
  computed: {
    // 刷新按钮、详细信息行按钮，均需要监听
    showRefreshDetailButt: function () {
      return this.showRefresh, this.detailButt;
    },
    // 是否显示行计数
    lineCountButt: function () {
      return this.$store.getters.getLineCountButt;
    },
    // 是否属于查找多选字段
    isLookMultiple() {
      return (fieldType) => {
        if (fieldType === "MR") {
          return true;
        }
        return false;
      };
    },
  },
  watch: {
    showRefreshDetailButt: {
      handler() {
        // 若是当前刷新按钮显示，则不需做处理；
        if (this.showRefresh) return;
        // 不显示刷新按钮，此时再判断是否展示了详细信息行
        if (this.detailButt) {
          this.isShowDetailData = true;
        } else {
          this.isShowDetailData = false;
        }
      },
      deep: true,
      immediate: true,
    },
    listCheckedFields: {
      handler() {
        this.$nextTick(() => {
          this.columnDrop();
        });
      },
      immediate: true,
      deep: true,
    },
    // 监听接收的已选择的组行数据
    chooseRowFileds: {
      handler() {
        this.$nextTick(() => {
          this.chooseRowFiledsData = this.chooseRowFileds; // 在拖拽时需要更改数据，所以不能直接使用props中的数据
        });
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    loadmore() {
      this.$emit("loadMoreData", this.total);
    },
    clickImg(item) {
      this.$emit("clickImg", item);
    },
    sortingOrder(item, $event) {
      // 排序
      this.$emit("sortingOrder", item, $event);
    },
    deleteGroupRow() {
      this.$emit("deleteGroupRow", DataTransferItem);
    },
    deleteGroupColumn(item) {
      this.$emit("deleteGroupColumn", item);
    },
    deleteColumn(index) {
      this.$emit("deleteColumn", index);
    },
    clickColOperation(data) {
      this.$emit("clickColOperation", data);
    },
    statisticsCurs(data) {
      this.$emit("statisticsCurs", data);
    },
    /* 
      汇总式报表显示详细信息行时拖拽列的对应情况处理：
        1.普通列到普通列；列之间切换顺序
        2.普通列到分组；行分组数据添加
        3.分组到分组；分组之间切换顺序
        4.分组到普通列；行分组数据减少
    */
    columnToColumnDraps(oldIndex, newIndex, oldItem) {
      // 普通列 -> 普通列
      if (
        oldIndex >= this.transversegroupList.length &&
        newIndex >= this.transversegroupList.length
      ) {
        return;
      }
      // 普通列 -> 组行
      if (
        oldIndex >= this.transversegroupList.length &&
        newIndex < this.transversegroupList.length
      ) {
        this.chooseRowFiledsData.splice(newIndex, 0, oldItem);
      }
      // 组行 -> 组行
      if (
        oldIndex < this.transversegroupList.length &&
        newIndex < this.transversegroupList.length
      ) {
        this.chooseRowFiledsData = this.swapArr(
          this.chooseRowFiledsData,
          oldIndex,
          newIndex
        );
      }
      // 组行 -> 普通列
      if (
        oldIndex < this.transversegroupList.length &&
        newIndex >= this.transversegroupList.length
      ) {
        this.chooseRowFiledsData.splice(oldIndex, 1);
      }
    },
    // 将字段列表中字段拖拽至汇总式报表的行分组、普通列
    fieldlistToColumnDraps(content, index, type) {
      this.chooseReportColumnsData = JSON.parse(
        JSON.stringify(this.listCheckedFields)
      );
      if (type === "group") {
        // 将字段拖拽至分组
        this.chooseRowFiledsData.splice(index + 1, 0, content);
      } else if (type === "field") {
        // 将字段拖拽至普通列
        this.chooseReportColumnsData.splice(index + 1, 0, content);
      }
    },
    // 表格列的拖拽
    columnDrop() {
      const wrapperTr = document.querySelector(
        ".draggable .el-table__header-wrapper tr"
      );
      if (!wrapperTr) return;
    },
    // 字段列表拖拽字段至报表列
    handleTargetDrop(e, index, type) {
      let data = e.dataTransfer;
      if (!this.isJsonString(data.getData("text/plain"))) return;
      let content = JSON.parse(data.getData("text/plain"));
      // 如果是要将字段拖拽至分组且拖拽的字段为查找多选，则直接return
      if (type === "group" && this.isLookMultiple(content.schemefieldType))
        return;
      this.fieldlistToColumnDraps(content, index, type);
      this.dragRowColumnReportColumns(
        this.chooseReportColumnsData,
        "fieldlistToColumn"
      );
      e.preventDefault();
      // 通常不需要阻止冒泡，但是当出现容器嵌套时最好这么做
      // 它可以防止节点被添加到数组中两次
      e.stopPropagation();
    },
    // 拖拽事件传回index
    dragRowColumnReportColumns(newList, dropType) {
      // 有两种拖拽，列与列之间的拖拽、字段列表至列的拖拽
      if (dropType === "columnToColumn") {
        newList = newList.slice(this.transversegroupList.length);
      }
      let e = {
        chooseRowFiledsData: this.chooseRowFiledsData,
        choosedColumnFiledsData: this.choosedColumnFileds,
        // newList中包含分组列和普通列，所以只取后面的普通列即可。
        chooseReportColumnsData: newList,
      };
      this.$bus.$emit("dragRowColumnReportColumnsBus", e);
    },
    // 两个元素换位子
    swapArr(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    // 数组去重
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].id == arr[j].id) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    },
    // 判断是否可以转换为json数据
    isJsonString(str) {
      try {
        if (typeof JSON.parse(str) == "object") {
          return true;
        }
      } catch (e) {
        if( e ) {
          return false;
        }
      }
    },
  },
};
</script>