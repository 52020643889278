var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"draggable"},[(_vm.showTable)?_c('el-table',{directives:[{name:"loadmore",rawName:"v-loadmore",value:(_vm.loadmore),expression:"loadmore"}],ref:"listelTable",attrs:{"data":_vm.tableData,"border":"","height":"100%","fit":true,"cell-style":_vm.cellClass}},[_vm._l((_vm.listCheckedFields),function(item,index){return _c('el-table-column',{key:index + item.id,attrs:{"fixed":index==0 && _vm.appReport?'left':false,"prop":item.schemefield_name_alias,"min-width":"140"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"report_header",on:{"drop":function (e) { return _vm.handleTargetDrop(e, index); },"dragover":function($event){$event.preventDefault();}}},[_c('div',{ref:"headerData",refInFor:true},[_vm._v(_vm._s(item.labelName))]),_c('div',{on:{"click":function($event){return _vm.clickImg(item)}}},[_c('colOperation',{ref:"test",refInFor:true,attrs:{"chooseRowFileds":_vm.chooseRowFileds,"choosedColumnFileds":_vm.choosedColumnFileds,"groupIds":_vm.groupIds,"indexData":item,"dateGroupings":_vm.dateGroupings,"statisticsDatas":_vm.statisticsDatas,"sortDatas":_vm.sortDatas,"judgeNumber":_vm.judgeNumber,"appReport":_vm.appReport},on:{"sortingOrder":function($event){return _vm.sortingOrder(item, $event)},"deleteGroupRow":function($event){return _vm.deleteGroupRow(item)},"deleteGroupColumn":function($event){return _vm.deleteGroupColumn(item)},"deleteColumn":function($event){return _vm.deleteColumn(index)},"clickColOperation":_vm.clickColOperation,"statisticsCurs":_vm.statisticsCurs}})],1)])]},proxy:true},{key:"default",fn:function(scope){return [(scope.row.level01 === 'maxtotal')?_c('div',[(index === 0)?_c('div',[_vm._v(" "+_vm._s(_vm.$t("label.reportsSubscriptionPage.condition.aggregate"))+"("+_vm._s(scope.row.totalrecord)+") ")]):_c('div',{staticClass:"number_style"},[(
                scope.row[item.schemefield_name_alias + '$sum'] !== undefined
              )?_c('div',[_vm._v(" "+_vm._s(_vm.$t("label.summation"))+"："+_vm._s(scope.row[item.schemefield_name_alias + "$sum"])+" ")]):_vm._e(),(
                scope.row[item.schemefield_name_alias + '$avg'] !== undefined
              )?_c('div',[_vm._v(" "+_vm._s(_vm.$t("label.average"))+"："+_vm._s(scope.row[item.schemefield_name_alias + "$avg"])+" ")]):_vm._e(),(
                scope.row[item.schemefield_name_alias + '$max'] !== undefined
              )?_c('div',[_vm._v(" "+_vm._s(_vm.$t("label.maximum"))+"："+_vm._s(scope.row[item.schemefield_name_alias + "$max"])+" ")]):_vm._e(),(
                scope.row[item.schemefield_name_alias + '$min'] !== undefined
              )?_c('div',[_vm._v(" "+_vm._s(_vm.$t("label.minimun"))+"："+_vm._s(scope.row[item.schemefield_name_alias + "$min"])+" ")]):_vm._e()])]):_c('div',{class:{ number_style: _vm.judgeNumber(item) }},[(_vm.isMapTaskRel(item))?_c('div',[_vm._v(" "+_vm._s(_vm.mapTaskRel[scope.row[item.schemefield_name_alias]])+" ")]):_c('div',[(_vm.judgeNumber(item))?_c('div',[_vm._v(" "+_vm._s(scope.row[item.schemefield_name_alias])+" ")]):_c('div',[(_vm.isNameFiled(scope.row, item))?_c('a',{staticClass:"nameFiled",on:{"click":[function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.cardViewjump($event,scope.row,item, 'detail')},function($event){if(!$event.ctrlKey){ return null; }if($event.shiftKey||$event.altKey||$event.metaKey){ return null; }return _vm.cardViewjump($event,scope.row,item, true)},function($event){if(!$event.metaKey){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey){ return null; }return _vm.cardViewjump($event,scope.row,item, true)}]}},[_vm._v(" "+_vm._s(scope.row[item.schemefield_name_alias]))]):_c('span',[(
                    _vm.getCheckboxFiled(
                      item,
                      scope.row[item.schemefield_name_alias]
                    ).isCheckboxFiled
                  )?_c('span',[_c('el-checkbox',{attrs:{"value":_vm.getCheckboxFiled(
                        item,
                        scope.row[item.schemefield_name_alias]
                      ).checkboxFiledValue,"disabled":""}})],1):_c('span',[_vm._v(" "+_vm._s(scope.row[item.schemefield_name_alias]))])])])])])]}}],null,true)})}),(_vm.expressionLine.length > 0)?_c('el-table-column',{staticStyle:{"width":"auto","padding":"3px 6px 3px 4px"},attrs:{"prop":_vm.expressionLine[0].apiname,"min-width":"150"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"report_header"},[_c('div',[_vm._v(_vm._s(_vm.expressionLine[0].apiname))]),_c('div',{on:{"click":function($event){return _vm.clickImg(_vm.expressionLine[0])}}},[_c('colOperation',{ref:"test",attrs:{"chooseRowFileds":_vm.chooseRowFileds,"groupIds":_vm.groupIds,"appReport":_vm.appReport,"indexData":_vm.indexData,"dateGroupings":_vm.dateGroupings,"statisticsDatas":_vm.statisticsDatas,"sortDatas":_vm.sortDatas,"judgeNumber":_vm.judgeNumber},on:{"clickColOperation":_vm.clickColOperation,"statisticsCurs":_vm.statisticsCurs}})],1)])]},proxy:true},{key:"default",fn:function(scope){return [_c('div',{staticStyle:{"padding-left":"10px"}},[_vm._v(" "+_vm._s(scope.row["t0" + _vm.expressionLine[0].apiname])+" ")])]}}],null,false,1668892852)}):_vm._e()],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }