<!-- 列表式报表 -->
<template>
  <div class="draggable">
    <el-table
      ref="listelTable"
      :data="tableData"
      border
      height="100%"
      :fit="true"
      :cell-style="cellClass"
      v-if="showTable"
      v-loadmore="loadmore"
    >
      <el-table-column
        v-for="(item, index) in listCheckedFields"
        :key="index + item.id"
        :fixed="index==0 && appReport?'left':false"
        :prop="item.schemefield_name_alias"
        min-width="140"
      >
        <template #header>
          <div
            class="report_header"
            @drop="(e) => handleTargetDrop(e, index)"
            @dragover.prevent
          >
            <div ref="headerData">{{ item.labelName }}</div>
            <div @click="clickImg(item)">
              <colOperation
                :chooseRowFileds="chooseRowFileds"
                :choosedColumnFileds="choosedColumnFileds"
                :groupIds="groupIds"
                :indexData="item"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                :appReport="appReport"
                @sortingOrder="sortingOrder(item, $event)"
                @deleteGroupRow="deleteGroupRow(item)"
                @deleteGroupColumn="deleteGroupColumn(item)"
                @deleteColumn="deleteColumn(index)"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <!-- 合计行 -->
          <div v-if="scope.row.level01 === 'maxtotal'">
            <div v-if="index === 0">
              {{ $t("label.reportsSubscriptionPage.condition.aggregate") }}({{
                scope.row.totalrecord
              }})
            </div>
            <div class="number_style" v-else>
              <!-- 总和 -->
              <div
                v-if="
                  scope.row[item.schemefield_name_alias + '$sum'] !== undefined
                "
              >
                {{ $t("label.summation") }}：{{
                  scope.row[item.schemefield_name_alias + "$sum"]
                }}
              </div>
              <!-- 平均值 -->
              <div
                v-if="
                  scope.row[item.schemefield_name_alias + '$avg'] !== undefined
                "
              >
                {{ $t("label.average") }}：{{
                  scope.row[item.schemefield_name_alias + "$avg"]
                }}
              </div>
              <!-- 最大值 -->
              <div
                v-if="
                  scope.row[item.schemefield_name_alias + '$max'] !== undefined
                "
              >
                {{ $t("label.maximum") }}：{{
                  scope.row[item.schemefield_name_alias + "$max"]
                }}
              </div>
              <!-- 最小值 -->
              <div
                v-if="
                  scope.row[item.schemefield_name_alias + '$min'] !== undefined
                "
              >
                {{ $t("label.minimun") }}：{{
                  scope.row[item.schemefield_name_alias + "$min"]
                }}
              </div>
            </div>
          </div>
          <div v-else :class="{ number_style: judgeNumber(item) }">
            <!-- 判断是否需要根据api值去查找 -->
            <div v-if="isMapTaskRel(item)">
              {{ mapTaskRel[scope.row[item.schemefield_name_alias]] }}
            </div>
            <div v-else>
              <div v-if="judgeNumber(item)">
                {{ scope.row[item.schemefield_name_alias] }}
              </div>
              <div v-else>
                <!-- 判断是否可跳转，若是可以跳转则添加选项卡打开功能 -->
                <a
                  v-if="isNameFiled(scope.row, item)"
                  class="nameFiled"
                  @click.exact="cardViewjump($event,scope.row,item, 'detail')"
                  @click.ctrl.exact="cardViewjump($event,scope.row,item, true)"
                  @click.meta.exact="cardViewjump($event,scope.row,item, true)"
                >
                  {{ scope.row[item.schemefield_name_alias] }}</a
                >
                <span v-else>
                  <!-- 复选框字段 -->
                  <span
                    v-if="
                      getCheckboxFiled(
                        item,
                        scope.row[item.schemefield_name_alias]
                      ).isCheckboxFiled
                    "
                  >
                    <el-checkbox
                      :value="
                        getCheckboxFiled(
                          item,
                          scope.row[item.schemefield_name_alias]
                        ).checkboxFiledValue
                      "
                      disabled
                    ></el-checkbox>
                  </span>
                  <span v-else>
                    {{ scope.row[item.schemefield_name_alias] }}</span
                  ></span
                >
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <!-- 行级公式 -->
      <el-table-column
        :prop="expressionLine[0].apiname"
        style="width: auto; padding: 3px 6px 3px 4px"
        v-if="expressionLine.length > 0"
        min-width="150"
      >
        <template #header>
          <div class="report_header">
            <div>{{ expressionLine[0].apiname }}</div>
            <div @click="clickImg(expressionLine[0])">
              <colOperation
                :chooseRowFileds="chooseRowFileds"
                :groupIds="groupIds"
                :appReport="appReport"
                :indexData="indexData"
                :dateGroupings="dateGroupings"
                :statisticsDatas="statisticsDatas"
                :sortDatas="sortDatas"
                :judgeNumber="judgeNumber"
                @clickColOperation="clickColOperation"
                @statisticsCurs="statisticsCurs"
                ref="test"
              ></colOperation>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <div style="padding-left: 10px">
            {{ scope.row["t0" + expressionLine[0].apiname] }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import colOperation from "./colOperation";

export default {
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding, vnode) {
        let that = vnode.context;
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 300;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (
            scrollDistance <= sign &&
            that.runTo === true &&
            that.total <= that.TabularPagenum
          ) {
            that.total += 1;
            that.loadmore();
          }
          that.listelTableScrolltop=this.scrollTop;
        });
      },
    },
  },
  components: {
    colOperation,
  },
  props: {
    tableData: {
      // 行数据
      type: Array,
      default: () => [],
    },
    listCheckedFields: {
      // 列数据
      type: Array,
      default: () => [],
    },
    expressionLine: {
      // 行级公式
      type: Array,
      default: () => [],
    },
    cellClass: {
      // 表格改变单元格颜色方法
      type: Function,
      default: () => {},
    },
    judgeNumber: {
      // 判断是否是数字方法
      type: Function,
      default: () => {},
    },
    isMapTaskRel: {
      // 判断是否需要从mapTaskRel里根据api值获取对应数据
      type: Function,
      default: () => {},
    },
    mapTaskRel: {
      // 判断是否需要从mapTaskRel里根据api值获取对应数据
      type: Object,
      default: () => {},
    },
    isNameFiled: {
      // 判断是否可跳转，后端已做过判断，isAddLink为true即可跳转
      type: Function,
      default: () => {},
    },
    changeMicrometerLevel: {
      // 将数值类型转换为千分位格式
      type: Function,
      default: () => {},
    },
    cardViewjump: {
      // 点击跳转详情页name
      type: Function,
      default: () => {},
    },
    getCheckboxFiled: {
      // 判断字段是否为复选框类型
      type: Function,
      default: () => {},
    },
    checkboxFiledValue: {
      // 当字段为复选框类型时，对value值判断做处理用于value
      type: Boolean,
      default: false,
    },
    // colOperation组件需接收的数据
    chooseRowFileds: {
      type: Array,
      default: () => [],
    },
    choosedColumnFileds: {
      type: Array,
      default: () => [],
    },
    groupIds: {
      type: Array,
      default: () => [],
    },
    indexData: {
      type: Object,
      default: () => {},
    },
    dateGroupings: {
      type: Object,
      default: () => {},
    },
    statisticsDatas: {
      type: Object,
      default: () => {},
    },
    sortDatas: {
      type: Object,
      default: () => {},
    },
    // 是否是运行页
    runTo: {
      type: Boolean,
      default: false,
    },
    //可以滚动加载的次数
    TabularPagenum: {
      type: Number,
      default: 1,
    },
    // 区分移动端报表
    appReport: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showTable: true,
      total: 1,
      listelTableScrolltop:0,//列表式表格滚动条距离
    };
  },
  watch: {
    listCheckedFields: {
      handler() {
        this.$nextTick(() => {
          this.columnDrop();
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    loadmore() {
      this.$emit("loadMoreData", this.total);
    },
    clickImg(item) {
      this.$emit("clickImg", item);
    },
    sortingOrder(item, $event) {
      // 排序
      this.$emit("sortingOrder", item, $event);
    },
    deleteGroupRow() {
      this.$emit("deleteGroupRow", DataTransferItem);
    },
    deleteGroupColumn(item) {
      this.$emit("deleteGroupColumn", item);
    },
    deleteColumn(index) {
      this.$emit("deleteColumn", index);
    },
    clickColOperation(data) {
      this.$emit("clickColOperation", data);
    },
    // 统计
    statisticsCurs(data) {
      this.$emit("statisticsCurs", data);
    },
    // 表格列的拖拽
    columnDrop() {
      const wrapperTr = document.querySelector(
        ".draggable .el-table__header-wrapper tr"
      );
      if (!wrapperTr) return;
    },
    // 字段列表拖拽字段至报表列
    handleTargetDrop(e, index) {
      let data = e.dataTransfer;
      if (!this.isJsonString(data.getData("text/plain"))) return;
      let content = JSON.parse(data.getData("text/plain"));
      let newList = JSON.parse(JSON.stringify(this.listCheckedFields));
      newList.splice(index + 1, 0, content);
      this.dragRowColumnReportColumns(newList);
      e.preventDefault();
      // 通常不需要阻止冒泡，但是当出现容器嵌套时最好这么做
      // 它可以防止节点被添加到数组中两次
      e.stopPropagation();
    },
    // 拖拽事件传回index
    dragRowColumnReportColumns(newList) {
      let e = {
        chooseRowFiledsData: this.chooseRowFileds,
        choosedColumnFiledsData: this.choosedColumnFileds,
        chooseReportColumnsData: newList,
      };
      this.$bus.$emit("dragRowColumnReportColumnsBus", e);
    },
    // 判断是否可以转换为json数据
    isJsonString(str) {
      try {
        if (typeof JSON.parse(str) == "object") {
          return true;
        }
      } catch (e) {
        if( e ) {
          return false;
        }
      }
    },
  },
};
</script>