<!--
  表格中：升降序箭头显示以及列的操作气泡。
    所有类型报表，分组的排序箭头一直显示，默认为升序；
    分组外的列，未排序时，不显示箭头，排序后显示对应箭头。
-->
<template>
  <div class="colOperation">
    <!-- 行列分组的升降序箭头，行分组的升降序箭头为上下，列分组的升降序箭头为左右 -->
    <div class="sort_arrows" v-if="groupIds.indexOf(indexData.id) > -1 &&!appReport">
      <!-- 降序 -->
      <i
        :class="
          choosedColumnFileds.some((item) => item.id === indexData.id)
            ? 'el-icon-back'
            : 'el-icon-bottom'
        "
        v-if="
          sortDatas.orderfieldapi === indexData.schemefield_name_alias &&
          sortDatas.ordertype === 'desc'
        "
        @click="sortingOrder('asc')"
      ></i>
      <!-- 升序，默认升序 -->
      <i
        :class="
          choosedColumnFileds.some((item) => item.id === indexData.id)
            ? 'el-icon-right'
            : 'el-icon-top'
        "
        v-else
        @click="sortingOrder('desc')"
      ></i>
    </div>
    <!-- 除行列分组以外的其他需排序字段的箭头 -->
    <div class="sort_arrows" v-else>
      <!-- 升序 -->
      <i
        class="el-icon-top"
        v-if="
          sortDatas.orderfieldapi === indexData.schemefield_name_alias &&
          sortDatas.ordertype === 'asc'
        "
        @click="sortingOrder('desc')"
      ></i>
      <!-- 降序 -->
      <i
        class="el-icon-bottom"
        v-if="
          sortDatas.orderfieldapi === indexData.schemefield_name_alias &&
          sortDatas.ordertype === 'desc'
        "
        @click="sortingOrder('asc')"
      ></i>
    </div>
    <!-- 操作按钮及气泡 -->
    <el-popover
      ref="popover2"
      placement="bottom-start"
      :visible-arrow="false"
      width=""
      trigger="click"
      popper-class="colPopovers"
      v-model="visible"
    >
      <div class="col_operation">
        <!-- 升序 -->
        <el-button
          class="operation_01"
          @click="sortingOrder('asc')"
          :disabled="
            sortDatas.orderfieldapi === indexData.schemefield_name_alias &&
            sortDatas.ordertype === 'asc'
          "
          >{{ $t("vue_label_report_sortup") }}</el-button
        >
        <!-- 降序 -->
        <el-button
          class="operation_01 operation_desc"
          @click="sortingOrder('desc')"
          :disabled="
            sortDatas.orderfieldapi === indexData.schemefield_name_alias &&
            sortDatas.ordertype === 'desc'
          "
          >{{ $t("vue_label_report_sortdown") }}</el-button
        >
        <!-- 日期分组依据 -->
        <div
          class="operation_01 date_group"
          v-show="dateGroupingTo"
          @mouseover="dateGrouping"
        >
          <div>{{ $t("label.dategrouprelyon") }}</div>
          <svg class="icon more-imgs" aria-hidden="true">
            <use href="#icon-more"></use>
          </svg>
          <div class="all_date_types" v-show="dateTo">
            <div
              class="each_type"
              v-for="(item, index) in dateTypes"
              :key="index"
              @click="clickColOperation(item.id, index)"
            >
              <div>{{ item.label }}</div>
              <div v-show="typeCur === index">√</div>
            </div>
          </div>
        </div>
        <!-- 删除组行 -->
        <div
          class="operation_01 operation_02"
          v-show="deleteGroupRowTo"
          @click="deleteGroupRow"
        >
          {{ $t("vue_label_report_deletegrouprow") }}
        </div>
        <!-- 删除组列 -->
        <div
          class="operation_01 operation_02"
          v-show="deleteGroupColumnTo"
          @click="deleteGroupColumn"
        >
          {{ $t("vue_label_report_deletegroupcolumn") }}
        </div>
        <!-- 删除列 -->
        <div
          class="operation_01 operation_02"
          @click="deleteColumn"
          v-show="deleteColumnTo"
        >
          {{ $t("vue_label_report_deletecolumn") }}
        </div>
        <!-- 统计数据 -->
        <div
          class="operation_01 same_sequential"
          v-show="statisticsDataTo"
          @mouseover="statisticsData"
        >
          <div>{{ $t("vue_label_report_statistics") }}</div>
          <svg class="icon more-imgs" aria-hidden="true">
            <use href="#icon-more"></use>
          </svg>
          <div class="all_date_types" v-show="sequentialTos">
            <div
              class="each_type"
              v-for="(item, index) in statisticsTypes"
              :key="index"
              @click="statisticsCurs(item.value, index)"
            >
              <div>{{ item.label }}</div>
              <div v-show="sequentialCur[index]">√</div>
            </div>
          </div>
        </div>
      </div>
      <div slot="reference" class="img_div">
        <!-- 第三个 -->
        <svg v-if="!appReport" class="icon more-img" aria-hidden="true">
          <use href="#icon-more"></use>
        </svg>
        <svg v-if="appReport"  class="icon app-imgs" aria-hidden="true">
          <use href="#icon-button_icon_xiala_cli"></use>
        </svg>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { statisticsTypes } from "@/config/reportDataType/unitData"; // 统计类型

export default {
  props: {
    // 区分移动端报表
    appReport: {
      type: String,
      default: "",
    },
    indexData: {
      // 当前选中的表头信息
      type: Object,
      default: () => {},
    },
    groupIds: {
      // 分组id集合
      type: Array,
      default: () => [],
    },
    chooseRowFileds: {
      // 行分组字段集合
      type: Array,
      default: () => [],
    },
    choosedColumnFileds: {
      // 列分组字段集合
      type: Array,
      default: () => [],
    },
    dateGroupings: {
      // 日期分组依据信息
      type: Object,
      default: () => {},
    },
    statisticsDatas: {
      // 数值类型字段统计信息
      type: Object,
      default: () => {},
    },
    sortDatas: {
      // 排序信息
      type: Object,
      default: () => {},
    },
    judgeNumber: {
      // 判断是否为数值的方法
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      dateTo: false, // 是否显示日期分组依据
      visible: false, //弹框显示隐藏
      // 日期分组依据的选项集合
      dateTypes: [
        {
          label: this.$i18n.t("label.schedulejob.setup.schedule.day"),
          id: "day",
        },
        { label: this.$i18n.t("month"), id: "month" },
        { label: this.$i18n.t("label.login.access.year"), id: "year" },
        { label: this.$i18n.t("label.forecast.edit.kjnd"), id: "FY" },
        { label: this.$i18n.t("label.forecast.edit.kjjd"), id: "FQ" },
        { label: this.$i18n.t("label.custom.CQ"), id: "CQ" },
        { label: this.$i18n.t("lable.custom.year"), id: "CY" },
      ],
      typeCur: "",
      // 统计的选项集合
      statisticsTypes: statisticsTypes(),
      sequentialCur: [false],
      sequentialTos: false, // 统计值类型cur
      dateGroupingTo: true,
      deleteGroupRowTo: false,
      deleteGroupColumnTo: false,
      deleteColumnTo: true,
      statisticsDataTo: true,
      // 判断日期分组依据下标的index值
      dataIndex: "",
    };
  },
  mounted() {},
  created() {},
  updated() {},
  computed: {
    dataAndOperation: function () {
      return this.visible, this.indexData;
    },
  },
  watch: {
    /* 
      对选择的数据和操作框一起进行监听，判断字段可操作的项：
        操作项：升序、降序、删除组行、删除组列、日期分组依据、删除列、统计。
        1.升序、降序均有；
        2.行列分组：行分组：删除组行；列分组：删除组列；若是时间类型字段：日期分组依据；
        3.其他列：删除列；若是数字类型字段：统计；
    */
    dataAndOperation: function () {
      //属于行列分组
      if (this.groupIds.indexOf(this.indexData.id) > -1) {
        this.deleteColumnTo = false;
        // 判断是否为行分组字段
        if (
          this.chooseRowFileds.some((item) => item.id === this.indexData.id)
        ) {
          this.deleteGroupRowTo = true;
          this.deleteGroupColumnTo = false;
        } else {
          // 属于列分组
          this.deleteGroupRowTo = false;
          this.deleteGroupColumnTo = true;
        }
        // 日期
        if (
          this.indexData.schemefieldType == "D" ||
          this.indexData.schemefieldType == "F"
        ) {
          this.dateGroupingTo = true;
          this.statisticsDataTo = false;
          // 判断属于是行列分组中的哪一个
          if (
            this.chooseRowFileds.some((item) => item.id == this.indexData.id)
          ) {
            //属于行分组
            let index = this.chooseRowFileds.findIndex(
              (item) => item.id == this.indexData.id
            );
            switch (index) {
              case 0:
                this.dataIndex = this.dateTypes.findIndex(
                  (item) => item.id === this.dateGroupings.transversedatetypeone
                );
                this.typeCur = this.dataIndex;
                break;
              case 1:
                this.dataIndex = this.dateTypes.findIndex(
                  (item) => item.id === this.dateGroupings.transversedatetypetwo
                );
                this.typeCur = this.dataIndex;
                break;
              case 2:
                this.dataIndex = this.dateTypes.findIndex(
                  (item) =>
                    item.id === this.dateGroupings.transversedatetypethree
                );
                this.typeCur = this.dataIndex;

                break;
            }
          } else {
            let index = this.choosedColumnFileds.findIndex(
              (item) => item.id == this.indexData.id
            );
            switch (index) {
              case 0:
                this.dataIndex = this.dateTypes.findIndex(
                  (item) => item.id === this.dateGroupings.lengthwaysdatetypeone
                );
                this.typeCur = this.dataIndex;
                break;
              case 1:
                this.dataIndex = this.dateTypes.findIndex(
                  (item) => item.id === this.dateGroupings.lengthwaysdatetypetwo
                );
                this.typeCur = this.dataIndex;
                break;
            }
          }
        } else {
          //非日期
          this.dateGroupingTo = false;
          this.statisticsDataTo = false;
        }
      } else {
        // 非行列分组
        this.deleteGroupRowTo = false;
        this.deleteGroupColumnTo = false;
        this.dateGroupingTo = false;
        if (
          // 判断若是属于汇总表不展示详细信息行的数值类型字段的统计字段，则不需要删除列、统计。
          this.indexData.schemefield_name_alias !==
          this.indexData.schemefield_name_alias_obj
        ) {
          this.deleteColumnTo = false;
          this.statisticsDataTo = false;
          return;
        }
        this.deleteColumnTo = true;
        // 数值
        if (this.judgeNumber(this.indexData)) {
          this.statisticsDataTo = true;
          // 总和
          if (
            this.statisticsDatas.gatherfieldname.indexOf(this.indexData.id) > -1
          ) {
            this.sequentialCur[0] = true;
          } else {
            this.sequentialCur[0] = false;
          }
          // 平均值
          if (this.statisticsDatas.avgfield.indexOf(this.indexData.id) > -1) {
            this.sequentialCur[1] = true;
          } else {
            this.sequentialCur[1] = false;
          }
          // 最大值
          if (this.statisticsDatas.maxfield.indexOf(this.indexData.id) > -1) {
            this.sequentialCur[2] = true;
          } else {
            this.sequentialCur[2] = false;
          }
          // 最小值
          if (this.statisticsDatas.minfield.indexOf(this.indexData.id) > -1) {
            this.sequentialCur[3] = true;
          } else {
            this.sequentialCur[3] = false;
          }
        } else {
          //非数值
          this.dateGroupingTo = false;
          this.statisticsDataTo = false;
        }
      }
    },
  },
  methods: {
    // 排序：升序、降序
    sortingOrder(type) {
      this.dateTo = false;
      this.sequentialTos = false;
      this.visible = false;
      this.$emit("sortingOrder", type);
    },
    // 日期分组依据
    dateGrouping() {
      this.dateTo = true;
      this.sequentialTos = false;
    },
    // 点击日期操作
    clickColOperation(type, index) {
      this.typeCur = index;
      this.visible = false;
      this.$emit("clickColOperation", type);
    },
    // 删除组行
    deleteGroupRow() {
      this.dateTo = false;
      this.sequentialTos = false;
      this.visible = false;
      this.$emit("deleteGroupRow");
    },
    // 删除组列
    deleteGroupColumn() {
      this.dateTo = false;
      this.sequentialTos = false;
      this.visible = false;
      this.$emit("deleteGroupColumn");
    },
    // 删除列
    deleteColumn() {
      this.dateTo = false;
      this.sequentialTos = false;
      this.visible = false;
      this.$emit("deleteColumn");
    },
    // 计算
    calculation() {
      this.dateTo = false;
      this.sequentialTos = false;
    },
    // 统计数据
    statisticsData() {
      this.sequentialTos = true;
      this.dateTo = false;
    },
    // 选择统计值
    statisticsCurs(item, index) {
      this.sequentialCur[index] = true;
      this.$forceUpdate();
      this.visible = false;
      this.$emit("statisticsCurs", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.img_div {
  margin-left: 10px;
  border: 1px solid #c4c6ca;
  border-radius: 0.125rem;
  width: 16px;
  height: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    border-color: #7d98b3 !important;
  }
}
.more-img {
  width: 14px;
  height: 14px;
}
.more-imgs {
  width: 14px;
  height: 14px;
  transform: rotate(-90deg);
  margin-left: 10px;
}
.app-imgs{
  width: 10px;
  height: 10px;
}
.date_group,
.same_sequential {
  padding: 7px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.all_date_types {
  min-width: 100px;
  padding: 5px 0 9px 0;
  background: #ffffff;
  position: absolute;
  right: 6px;
  top: 24px;
  border: solid 1px #aaaaaa;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.04);
  .each_type {
    padding: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #080707;
    &:hover {
      background: #f3f2f2 !important;
      color: #005fb2;
    }
  }
}
.col_operation {
  padding: 5px 0;
  background: #ffffff;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 4px;
}
.operation_desc {
  margin-left: 0!important;
}
.operation_01 {
  padding: 7px 15px !important;
  color: #080707;
  &:hover {
    background: #f3f2f2 !important;
    color: #005fb2;
  }
  svg {
    width: 16px;
    height: 16px;
  }

}
.el-button {
  height: 30px;
  text-align: left;
  font-size: 12px;
  // background-color: #fff;
  color: #080707;
  border: none;
  margin: 0;
  padding: 0px;
}
.el-button.is-disabled {
  color: var(--lwc-colorTextButtonDefaultDisabled, rgb(221, 219, 218));
}

.operation_02 {
  padding: 7px;
  cursor: pointer;
}
</style>

